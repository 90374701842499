import React from 'react';
import classnames from 'classnames';

const Caption = ({
  children,
  className,
  bgColor = 'bg-pomegranate',
  ...props
}) => {
  return (
    <div className={classnames('flex items-baseline', className)} {...props}>
      <i
        className={classnames(
          'inline-block flex-none h-3.5 w-3.5 rounded-full mr-2.5',
          bgColor
        )}
      ></i>
      <span className="oc-small uppercase">{children}</span>
    </div>
  );
};

export default Caption;
