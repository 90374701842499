import React from 'react';
import classnames from 'classnames';
import Link from 'components/Link';

import Caption from 'components/partials/Caption';
import Tags from 'components/partials/Tags';

// import WpImage from 'components/WPImage';

// import DefaultPreview from 'images/temp/related-articles/img-1.jpg';

import { card, tagsWrap } from './styles.module.scss';

const CardRelatedArticles = ({
  data,
  className = '',
  cardHover = '',
  categoryColor = 'bg-pomegranate',
  tagColor,
}) => {
  return (
    <div
      className={classnames(
        'flex flex-col h-full bg-white',
        className,
        cardHover,
        card,
      )}
    >
      <div className="p-5">
        {data.categories?.nodes[0] ? (
          <Caption bgColor={categoryColor}>
            {data.categories.nodes[0].name}
          </Caption>
        ) : null}
      </div>

      {/* <div className="pb-6 sm:pb-8">
        <WpImage
          className={classnames(
            image,
            'h-52 sm:h-56 lg:h-64 w-full object-cover',
          )}
          src={data.featuredImage?.node.sourceUrl || DefaultPreview}
          alt={data.featuredImage?.node.altText || ''}
        />
      </div> */}

      <div className="mt-16 lg:mt-28 px-5 pb-8 sm:px-6 sm:pb-10">
        <Link href={data.uri} className="inline-block hover:underline">
          <h5 className="oc-h5 font-medium">{data.title}</h5>
        </Link>
      </div>

      <div
        className={classnames('mt-auto px-5 pb-6 sm:px-6 sm:pb-8', tagsWrap)}
      >
        {data?.tags?.nodes ? (
          <Tags tags={data.tags.nodes} tagColor={tagColor} />
        ) : null}
      </div>
    </div>
  );
};

export default CardRelatedArticles;
