import React from 'react';
import classnames from 'classnames';

import Link from 'components/Link';

const Tags = ({
  tags,
  className = '',
  tagColor = 'border-pomegranate text-pomegranate hover:bg-pomegranate hover:text-white',
}) => {
  return (
    <ul className={classnames('flex gap-3 flex-wrap', className)}>
      {tags.map(({ name, slug }) => (
        <li key={name}>
          <Link
            href={slug}
            className={classnames(
              'block py-1.5 px-4 rounded-full border  oc-small uppercase pointer-events-none',
              tagColor,
            )}
          >
            {name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Tags;
